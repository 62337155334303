const Ddata = [
    {
      cover: "./images/discount/discount-1.png",
      name: "BenuX 2022",
      price: "$250",
    },
    {
      cover: "./images/discount/discount-2.png",
      name: "Sony TV 1080p",
      price: "$450",
    },
    {
      cover: "./images/discount/discount-3.png",
      name: "Sony PS4",
      price: "$50",
    },
    {
      cover: "./images/discount/discount-4.png",
      name: "Setgearr 2022",
      price: "$100",
    },
    {
      cover: "./images/discount/discount-5.png",
      name: "Tony BGB",
      price: "$20",
    },
    {
      cover: "./images/discount/discount-6.png",
      name: "RG products",
      price: "$200",
    },
    {
      cover: "./images/discount/discount-7.png",
      name: "Ranasonic 2022",
      price: "$300",
    },
    {
      cover: "./images/discount/discount-8.png",
      name: "Pune HD",
      price: "$30",
    },
    {
      cover: "./images/discount/discount-9.png",
      name: "Sony CCTV",
      price: "$80",
    },
  ]
  export default Ddata