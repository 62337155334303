import React from 'react'
import cart from '../Cart/cart'
import { Link } from "react-router-dom"
const Search = ({CartItem}) => {

  
  return (
    <>
   <section >
   
   </section>
    </>
  )
}

export default Search
