import React from 'react'
import Slidecard from './Slidecard'
const Slider = () => {
    return (
        <>
          <section className='homeSlide contentWidth'>
            <div className='container'>
              <Slidecard />
            </div>
          </section>
        </>
      )
}

export default Slider
